import React from 'react';

import { Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Navigation } from 'swiper/modules';
import { Swiper } from 'swiper/react';

import Headline from '@/components/adView/common/Headline';

import 'swiper/css';
import 'swiper/css/navigation';

type CardsSwiperProps = {
  title: string,
  showAllUrl: string,
  infoModalComponent: React.ReactNode,
  children: React.ReactNode,
  containerPaddingOff: boolean,
};

const CardsSwiper = ({
  children, infoModalComponent, showAllUrl, title, containerPaddingOff,
}: CardsSwiperProps): React.ReactNode => {
  const theme = useTheme();

  return (
    <Container style={containerPaddingOff ? { paddingLeft: 0, paddingRight: 0 } : {}}>
      <Headline
        infoModalComponent={infoModalComponent}
        readMoreData={{
          href: showAllUrl,
          text: 'Összes',
        }}
      >
        {title}
      </Headline>
      <Swiper
        id="homepage-shops-swiper"
        className="custom-swiper big-nav-buttons"
        modules={[Navigation]}
        loop
        slidesPerView={4}
        spaceBetween={theme.spacing(4)}
        breakpoints={{
          [theme.breakpoints.values.xs]: { slidesPerView: 1, slidesPerGroup: 1 },
          [theme.breakpoints.values.sm]: { slidesPerView: 2, slidesPerGroup: 2 },
          [theme.breakpoints.values.md]: { slidesPerView: 3, slidesPerGroup: 3 },
        }}
        style={{ paddingBottom: '2px' }}
        navigation={{ disabledClass: 'navigation-disabled' }}
      >
        {children}
      </Swiper>
    </Container>
  );
};

export default CardsSwiper;

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const BluebarTitle: React.FunctionComponent<{ title: string }> = ({ title }) => {
  const theme = useTheme();

  return (
    <Typography
      variant="h4"
      component="h1"
      px={4}
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        minHeight: '60px',
        color: 'secondary.main',
        boxShadow: `0 -4px 0 0 ${theme.palette.primary.main} inset`,
      }}
    >
      {title}
    </Typography>
  );
};

export default BluebarTitle;

import {
  Container,
  Stack,
  Box,
  Typography,
  Paper,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import tilesData from './TilesData'; // Move the import statement here
import Headline from '@/components/adView/common/Headline';
import 'swiper/css/navigation';
import { TilesData } from '@/components/home/Tiles/types';

const Tiles = (): JSX.Element => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container>
      <Headline>Miért a Jófogás?</Headline>
      {!isSmallScreen ? (
        <Stack
          direction="row"
          spacing={4}
        >
          {tilesData.map((item: TilesData) => (
            <Paper
              key={item.key}
              data-testid={item.key}
              flex={1}
              component={Box}
              height="150px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              border="4px solid #fff"
              sx={{
                backgroundImage: `url(${item.background})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <Typography
                variant="h3"
                color="white"
                align="center"
                whiteSpace="pre-line"
              >
                {item.title}
              </Typography>
            </Paper>
          ))}
        </Stack>
      )
        : (
          <Swiper
            id="tiles-swiper"
            className="custom-swiper"
            breakpoints={{
              [theme.breakpoints.values.xs]: {
                slidesPerView: 1,
              },
              [theme.breakpoints.values.sm]: {
                slidesPerView: 2,
                spaceBetween: theme.spacing(2),
              },
            }}
            modules={[Navigation]}
            navigation
          >
            {tilesData.map((item: TilesData) => (
              <SwiperSlide
                key={item.key}
              >
                <Paper
                  data-testid={item.key}
                  flex={1}
                  component={Box}
                  height="150px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  border="4px solid #fff"
                  sx={{
                    backgroundImage: `url(${item.background})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                >
                  <Typography
                    variant="h3"
                    color="white"
                    align="center"
                    whiteSpace="pre-line"
                  >
                    {item.title}
                  </Typography>
                </Paper>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
    </Container>
  );
};

export default Tiles;

import { Container, Grid } from '@mui/material';

import BluebarTitle from './title';
import BluebarVerticalLinks from './vertical-links';

interface Props {
  children: React.ReactNode
}

const Bluebar: React.FunctionComponent<Props> = ({ children }) => (
  <Grid container sx={{ bgcolor: 'background.blueBar' }}>
    <Container>
      {children}
    </Container>
  </Grid>
);

export default Object.assign(Bluebar, {
  VerticalLinks: BluebarVerticalLinks,
  Title: BluebarTitle,
});

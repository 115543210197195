import {
  Box, Container, Grid, Paper, Stack, useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import HeroBackgroundSVG from '@/components/home/Hero/HeroBackgroundSVG';
import HeroContent from '@/components/home/Hero/HeroContent';
import 'swiper/css';
import 'swiper/css/pagination';
import { HeroProps, HeroSlide } from '@/components/home/Hero/types';
import { heroImageUrl } from '@/config';
import { clickHeroCTA } from '@/helpers/tracking/homepage';

const Hero = ({ heroData }: HeroProps): JSX.Element | null => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  if (!heroData?.length) {
    return null;
  }
  const handleSlideClick = (url: string, index: number): void => {
    clickHeroCTA(`Position ${index + 1}`);
    window.open(url, url.includes('jofogas.hu') ? '_self' : '_blank');
  };

  return (
    <Grid item xs={12} mb={4} zIndex="2">
      <Container>
        <Swiper
          loop
          style={{ borderRadius: '4px' }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          className="custom-swiper"
          navigation
          slidesPerView={1}
          pagination={{
            horizontalClass: 'custom-horizontal',
            clickable: true,
          }}
          modules={[Navigation, Pagination, Autoplay]}
        >

          {heroData.map((slide: HeroSlide, index: number) => (
            <SwiperSlide
              key={slide.title.slice(index, 10)}
              style={{
                height: 'auto',
              }}
            >
              {!isSmallScreen ? (
                <Stack
                  sx={{
                    backgroundImage: `url("${heroImageUrl}${slide.image}")`,
                    cursor: 'pointer',
                  }}
                  height={{ xs: 'auto', sm: '362px' }}
                  position="relative"
                  justifyContent="end"
                  boxShadow="content-box"
                  p={4}
                  onClick={() => handleSlideClick(slide.cta.url, index)}
                >
                  <>
                    <HeroBackgroundSVG />
                    <Stack
                      zIndex="2"
                      maxWidth="fit-content"
                      height="144px"
                    >
                      <HeroContent content={slide} position={index + 1} />
                    </Stack>
                  </>
                </Stack>
              ) : (
                <Stack
                  pb={1}
                  sx={{
                    height: '100%',
                    alignItems: 'stretch',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleSlideClick(slide.cta.url, index)}
                >
                  <>
                    <Box
                      sx={{
                        height: '240px',
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflow: 'hidden',
                      }}
                    >
                      <img
                        style={{
                          position: 'absolute',
                          height: '100%',
                          zIndex: '1',
                        }}
                        alt={slide.title}
                        height="100%"
                        width="auto"
                        src={`${heroImageUrl}${slide.image}`}
                      />
                    </Box>
                    <Paper
                      sx={{
                        borderRadius: '4px',
                        zIndex: '3',
                        flex: '1',
                        marginTop: '-4px',
                      }}
                    >
                      <Stack
                        zIndex="10"
                        width="100%"
                        height="100%"
                        direction="column"
                        justifyContent="start"
                        alignItems="space-around"
                        spacing={2}
                        p={4}
                      >
                        <HeroContent content={slide} position={index + 1} />
                      </Stack>
                    </Paper>
                  </>
                </Stack>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </Grid>
  );
};

export default Hero;
